module.exports = [{
      plugin: require('../plugins/splitio-plugin/gatsby-browser.tsx'),
      options: {"plugins":[],"sdk":{"authorizationKey":"m8rohueeufottd7v2qlbrfh1vk5r09so03d3","trafficType":"anonymous"},"pathsToMask":["/funerals/funeral-plans","/","/apply-for-probate","/landing/apply-for-probate"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Farewill","start_url":"/","background_color":"#FFDF4E","theme_color":"#FFDF4E","display":"minimal-ui","icon":"static/icons/circle-lockup-logo-icon.svg","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"27ec4242ccc074acf0e64418228f0928"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/tmp/build_839862fb/src/PageProvider.tsx"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://01ebc765fa404afda3bd500cdb73aabf@sentry.io/1798616","environment":"production","release":"99a64eedf01f9bed24a2b0ebdedefe6013c3cd5f","sampleRate":0.1,"tracesSampleRate":0.1},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/lib/gatsby","projectRoot":"/tmp/build_839862fb"},
    }]
